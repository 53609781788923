<template>
  <div>
    <header>
      <h1>Satellite Area Measurement Tool</h1>
      <meta name="description" content="Use the Satellite Area Measurement Tool to draw, measure, and analyze areas on a map with ease. Perfect for land surveying and real estate analysis." />
      <meta name="keywords" content="Satellite Area, Area Measurement, Map Tools, Land Surveying, Real Estate Analysis" />
      <meta name="author" content="satellitearea.com" />
    </header>
    <div id="map-controls">
      <input id="search-bar" type="text" placeholder="Search places" aria-label="Search for places on the map" />
      <button @click="startDrawing" aria-label="Start drawing an area on the map">Draw Area</button>
      <button @click="clearAllPolygons" aria-label="Clear all drawn areas">Clear All</button>
    </div>
    <div id="map" style="height: 70vh; width: 100%;" role="region" aria-label="Interactive map for drawing and measuring areas"></div>
    <div id="bottom-panel">
      <div id="polygons-list">
        <div v-for="(polygonWrapper, index) in polygons" :key="polygonWrapper.id" class="polygon-item">
          <div v-if="polygonWrapper.area && polygonWrapper.perimeter">
            <strong class="areaTitle">Area {{ index + 1 }}</strong>
            <strong class="areaNumber">Area:</strong>  
            <p class="numbers">
            {{ formatNumber(polygonWrapper.area.m2) }} m²,
            {{ formatNumber(polygonWrapper.area.ft2) }} ft², 
            {{ formatNumber(polygonWrapper.area.acres) }} acres, 
            {{ formatNumber(polygonWrapper.area.mi2) }} mi², 
            {{ formatNumber(polygonWrapper.area.km2) }} km²
            </p>
            
            <strong class="perNumber">Perimeter:</strong> 
            <p class="numbers">
            {{ formatNumber(polygonWrapper.perimeter.m) }} m, 
            {{ formatNumber(polygonWrapper.perimeter.ft) }} ft, 
            {{ formatNumber(polygonWrapper.perimeter.mi) }} mi, 
            {{ formatNumber(polygonWrapper.perimeter.km) }} km
          </p>
          </div>
          <a href="#" @click.prevent="clearPolygon(index)" aria-label="Clear this area">Clear</a>
        </div>
      </div>
      <div id="info">
        <strong>Total Area and Perimeter:</strong><br>
        <strong>Total Area:</strong> {{ formatNumber(totalArea.m2) }} m², {{ formatNumber(totalArea.ft2) }} ft², {{ formatNumber(totalArea.acres) }} acres, {{ formatNumber(totalArea.mi2) }} mi², {{ formatNumber(totalArea.km2) }} km²<br>
        <strong>Total Perimeter:</strong> {{ formatNumber(totalPerimeter.m) }} m, {{ formatNumber(totalPerimeter.ft) }} ft, {{ formatNumber(totalPerimeter.mi) }} mi, {{ formatNumber(totalPerimeter.km) }} km
      </div>
    </div>
  </div>
</template>
  
<script>
import { Loader } from "@googlemaps/js-api-loader";
import { reactive, toRaw } from "vue";
import './styles.css'; // Import the CSS file

const loaderOptions = {
  apiKey: "AIzaSyDJlcHZvTGhdCO0hh4_HS8S44v4sEhs36E",
  version: "weekly",
  libraries: ["drawing", "geometry", "places"]
};

let loaderInstance = null;

export default {
  name: "GoogleMap",
  data() {
    return {
      map: null,
      drawingManager: null,
      totalArea: { m2: 0, ft2: 0, acres: 0, mi2: 0, km2: 0 },
      totalPerimeter: { m: 0, ft: 0, mi: 0, km: 0 },
      polygons: reactive([]),
      nextPolygonIndex: 1 // Track the next polygon index
    };
  },
  mounted() {
    this.initMap();
  },
  methods: {
    initMap() {
      if (!loaderInstance) {
        loaderInstance = new Loader(loaderOptions);
      }

      loaderInstance.load().then(() => {
        this.map = new window.google.maps.Map(document.getElementById("map"), {
          center: { lat: -34.397, lng: 150.644 },
          zoom: 1
        });

        this.drawingManager = new window.google.maps.drawing.DrawingManager({
          drawingMode: window.google.maps.drawing.OverlayType.POLYGON,
          drawingControl: false,
          polygonOptions: {
            fillColor: "#ffff00",
            fillOpacity: 0.5,
            strokeWeight: 2,
            clickable: true,
            editable: true,
            draggable: true,
            zIndex: 1
          }
        });

        this.drawingManager.setMap(this.map);

        window.google.maps.event.addListener(this.drawingManager, 'polygoncomplete', this.onPolygonComplete);

        const input = document.getElementById("search-bar");
        const searchBox = new window.google.maps.places.SearchBox(input);

        this.map.addListener("bounds_changed", () => {
          searchBox.setBounds(this.map.getBounds());
        });

        searchBox.addListener("places_changed", () => {
          const places = searchBox.getPlaces();

          if (places.length == 0) {
            return;
          }

          // Clear out the old markers.
          this.clearMarkers();

          // For each place, get the icon, name and location.
          const bounds = new window.google.maps.LatLngBounds();
          places.forEach((place) => {
            if (!place.geometry || !place.geometry.location) {
              console.log("Returned place contains no geometry");
              return;
            }

            // Create a marker for each place.
            const marker = new window.google.maps.Marker({
              map: this.map,
              title: place.name,
              position: place.geometry.location
            });

            this.markers.push(marker);

            if (place.geometry.viewport) {
              // Only geocodes have viewport.
              bounds.union(place.geometry.viewport);
            } else {
              bounds.extend(place.geometry.location);
            }
          });
          this.map.fitBounds(bounds);
        });

        this.markers = [];
      });
    },
    startDrawing() {
      this.drawingManager.setDrawingMode(window.google.maps.drawing.OverlayType.POLYGON);
    },
    onPolygonComplete(polygon) {
      this.drawingManager.setDrawingMode(null);

      const id = Date.now(); // Use a timestamp as a unique identifier
      polygon.id = id; // Assign the unique ID to the polygon

      const listeners = [
        window.google.maps.event.addListener(polygon, 'click', () => {
          this.calculateAndDisplayMetrics(polygon);
          polygon.setEditable(true);
          polygon.setDraggable(true);
        }),
        window.google.maps.event.addListener(polygon, 'dragend', () => {
          this.calculateAndDisplayMetrics(polygon);
        }),
        window.google.maps.event.addListener(polygon.getPath(), 'insert_at', () => {
          this.calculateAndDisplayMetrics(polygon);
        }),
        window.google.maps.event.addListener(polygon.getPath(), 'remove_at', () => {
          this.calculateAndDisplayMetrics(polygon);
        }),
        window.google.maps.event.addListener(polygon.getPath(), 'set_at', () => {
          this.calculateAndDisplayMetrics(polygon);
        })
      ];

      const polygonWrapper = {
        id,
        polygon,
        area: {
          m2: 0,
          ft2: 0,
          acres: 0,
          mi2: 0,
          km2: 0
        },
        perimeter: {
          m: 0,
          ft: 0,
          mi: 0,
          km: 0
        },
        listeners,
        label: this.createPolygonLabel(polygon, this.nextPolygonIndex)
      };

      this.polygons.push(polygonWrapper);
      this.nextPolygonIndex += 1; // Increment the next polygon index
      this.calculateAndDisplayMetrics(polygon);
    },
    calculateAndDisplayMetrics(polygon) {
      const path = polygon.getPath();
      if (path.getLength() < 3) {
        console.warn('Polygon path is too short to calculate area and perimeter');
        return;
      }

      const areaM2 = window.google.maps.geometry.spherical.computeArea(path);
      const perimeterM = window.google.maps.geometry.spherical.computeLength(path);

      console.log('Area (m2):', areaM2);
      console.log('Perimeter (m):', perimeterM);

      const polygonWrapper = this.polygons.find(p => p.id === polygon.id);
      if (polygonWrapper) {
        polygonWrapper.area = {
          m2: areaM2,
          ft2: areaM2 * 10.7639,
          acres: areaM2 * 0.000247105,
          mi2: areaM2 * 3.861e-7,
          km2: areaM2 * 1e-6
        };

        polygonWrapper.perimeter = {
          m: perimeterM,
          ft: perimeterM * 3.28084,
          mi: perimeterM * 0.000621371,
          km: perimeterM * 0.001
        };

        // Update the label position
        this.updatePolygonLabel(polygonWrapper);

        // Trigger reactivity by creating a new reference
        this.polygons = [...this.polygons];
        console.log('Polygon area and perimeter updated:', polygonWrapper);
      } else {
        console.error('PolygonWrapper not found for the given polygon');
      }

      this.updateTotalMetrics();
    },
    updateTotalMetrics() {
      this.totalArea = this.polygons.reduce((acc, curr) => ({
        m2: acc.m2 + curr.area.m2,
        ft2: acc.ft2 + curr.area.ft2,
        acres: acc.acres + curr.area.acres,
        mi2: acc.mi2 + curr.area.mi2,
        km2: acc.km2 + curr.area.km2
      }), { m2: 0, ft2: 0, acres: 0, mi2: 0, km2: 0 });

      this.totalPerimeter = this.polygons.reduce((acc, curr) => ({
        m: acc.m + curr.perimeter.m,
        ft: acc.ft + curr.perimeter.ft,
        mi: acc.mi + curr.perimeter.mi,
        km: acc.km + curr.perimeter.km
      }), { m: 0, ft: 0, mi: 0, km: 0 });
    },
    clearPolygon(index) {
      const polygonWrapper = this.polygons[index];
      toRaw(polygonWrapper.polygon).setMap(null);
      toRaw(polygonWrapper.label).setMap(null);
      polygonWrapper.listeners.forEach(listener => window.google.maps.event.removeListener(listener));
      this.polygons.splice(index, 1);
      this.updatePolygonLabels();
      this.updateTotalMetrics();
    },
    clearAllPolygons() {
      this.polygons.forEach(polygonWrapper => {
        toRaw(polygonWrapper.polygon).setMap(null);
        toRaw(polygonWrapper.label).setMap(null);
        polygonWrapper.listeners.forEach(listener => window.google.maps.event.removeListener(listener));
      });
      this.polygons.splice(0, this.polygons.length);
      this.nextPolygonIndex = 1; // Reset the polygon index counter
      this.updateTotalMetrics();
    },
    clearMarkers() {
      this.markers.forEach((marker) => marker.setMap(null));
      this.markers = [];
    },
    formatNumber(value) {
      return value.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
    },
    createPolygonLabel(polygon, index) {
      const bounds = new window.google.maps.LatLngBounds();
      polygon.getPath().forEach((path) => bounds.extend(path));

      const center = bounds.getCenter();

      const label = new window.google.maps.Marker({
        position: center,
        map: this.map,
        label: {
          text: index.toString(),
          color: 'black',
          fontSize: '16px',
          fontWeight: 'bold'
        },
        icon: {
          path: window.google.maps.SymbolPath.CIRCLE,
          scale: 0
        }
      });

      return label;
    },
    updatePolygonLabel(polygonWrapper, index = null) {
      const bounds = new window.google.maps.LatLngBounds();
      polygonWrapper.polygon.getPath().forEach((path) => bounds.extend(path));

      const center = bounds.getCenter();

      polygonWrapper.label.setPosition(center);
      polygonWrapper.label.setLabel({
        text: (index !== null ? index + 1 : this.polygons.indexOf(polygonWrapper) + 1).toString(),
        color: 'black',
        fontSize: '16px',
        fontWeight: 'bold'
      });
    },
    updatePolygonLabels() {
      this.polygons.forEach((polygonWrapper, index) => {
        this.updatePolygonLabel(polygonWrapper, index);
      });
    }
  }
};
</script>
