<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
export default {
  name: 'App',
  metaInfo: {
    title: 'Satellite Area Tool',
    meta: [
      { charset: 'utf-8' },
      { name: 'viewport', content: 'width=device-width,initial-scale=1.0' },
      { name: 'description', content: 'Your description here' }
    ],
    link: [
      { rel: 'icon', href: '/favicon.png' }
    ]
  }
};
</script>

<style>
nav {
  padding: 1em;
  background-color: #f8f8f8;
}

nav a {
  margin: 0 15px;
  text-decoration: none;
  color: #333;
}

nav a.router-link-active {
  font-weight: bold;
  color: #42b983;
}
</style>
