import { createApp } from 'vue';
import App from './App.vue';
import router from './router';

// Initialize Google Analytics
window.dataLayer = window.dataLayer || [];
function gtag() { window.dataLayer.push(arguments); }
window.gtag = gtag;

router.afterEach((to) => {
  gtag('config', 'G-84G2WX2216', { 'page_path': to.fullPath });
});

const app = createApp(App);

app.use(router);
app.mount('#app');
