<template>
    <div>
      <h1>Google Solar Map</h1>
      <div id="map" style="height: 70vh; width: 100%;"></div>
      <div>
        <button @click="getBuildingInsights">Get Building Insights</button>
        <button @click="getDataLayers">Get Data Layers</button>
      </div>
      <div v-if="buildingInsights">
        <h2>Building Insights</h2>
        <pre>{{ buildingInsights }}</pre>
      </div>
      <div v-if="dataLayers">
        <h2>Data Layers</h2>
        <pre>{{ dataLayers }}</pre>
      </div>
    </div>
  </template>
  
  <script>
  import { Loader } from "@googlemaps/js-api-loader";
  import axios from 'axios';
  
  const loaderOptions = {
    apiKey: "AIzaSyDJlcHZvTGhdCO0hh4_HS8S44v4sEhs36E", // Your actual API key
    version: "weekly",
    libraries: ["drawing", "geometry", "places"]
  };
  
  let loaderInstance = null;
  
  export default {
    name: "SolarMap",
    data() {
      return {
        map: null,
        buildingInsights: null,
        dataLayers: null,
      };
    },
    mounted() {
      this.initMap();
    },
    methods: {
      initMap() {
        if (!loaderInstance) {
          loaderInstance = new Loader(loaderOptions);
        }
  
        loaderInstance.load().then(() => {
          this.map = new window.google.maps.Map(document.getElementById("map"), {
            center: { lat: -34.397, lng: 150.644 },
            zoom: 8
          });
  
          // Add additional solar map logic here
        });
      },
      async getBuildingInsights() {
        const apiKey = 'AIzaSyDJlcHZvTGhdCO0hh4_HS8S44v4sEhs36E'; // Your actual API key
        const latitude = 37.4450;
        const longitude = -122.1390;
        const url = `https://solar.googleapis.com/v1/buildingInsights:findClosest?location.latitude=${latitude}&location.longitude=${longitude}&requiredQuality=HIGH&key=${apiKey}`;
  
        try {
          const response = await axios.get(url);
          this.buildingInsights = response.data;
        } catch (error) {
          console.error('Error fetching building insights:', error);
        }
      },
      async getDataLayers() {
        const apiKey = 'AIzaSyDJlcHZvTGhdCO0hh4_HS8S44v4sEhs36E'; // Your actual API key
        const latitude = 37.4450;
        const longitude = -122.1390;
        const radiusMeters = 100;
        const url = `https://solar.googleapis.com/v1/dataLayers:get?location.latitude=${latitude}&location.longitude=${longitude}&radiusMeters=${radiusMeters}&view=FULL_LAYERS&requiredQuality=HIGH&exactQualityRequired=true&pixelSizeMeters=0.5&key=${apiKey}`;
  
        try {
          const response = await axios.get(url);
          this.dataLayers = response.data;
        } catch (error) {
          console.error('Error fetching data layers:', error);
        }
      }
    }
  };
  </script>
  
  <style scoped>
  #map {
    height: 70vh;
    width: 100%;
  }
  
  button {
    margin: 10px;
  }
  
  pre {
    background-color: #f8f8f8;
    padding: 10px;
    border: 1px solid #ddd;
  }
  </style>
  